import React from 'react';

import Layout from '../components/layout';
import Work from '../components/work';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Work"
      description="Photos of Katharina Welper's work including Mosaic, Painting on Canvas and Textile Art."
    />
    <Work locale="en" />
  </Layout>
);

export default IndexPage;
